<script setup lang="ts">
import type { PropType } from "vue";

const SNOWS = {
    "1": "/images/christmas/snows/1.png",
    "2": "/images/christmas/snows/2.svg",
    "3": "/images/christmas/snows/3.svg",
    "4": "/images/christmas/snows/4.svg",
    "5": "/images/christmas/snows/5.svg",
    "6": "/images/christmas/snows/6.svg"
};

defineProps({
    type: {
        type: String as PropType<keyof typeof SNOWS>,
        required: true,
        default: "1"
    }
});
</script>

<template>
    <img :src="SNOWS[type]" :alt="'Christmas snow ' + type" loading="lazy" />
</template>

<style lang="scss">
.christmas-snow {
    background-repeat: no-repeat;
    background-position: -20px -20px;
}
</style>
